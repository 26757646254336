<template>
    <div class="pageMain">
      <!-- 企业备案 -->
      <div class="content">
        <!-- 左边列表 -->
        <CustomTables
          :data="tableData"
          height="55vh"
          :titleIcon="true"
          :total="total"
          class="tableHear"
          :headerCellStyle="headerCellStyle"
        >
          <template slot="search">
            <SearchLeft
              @search="search"
              @reset="reset"
              :infoShow="true"
              info="项目名称"
              :iconShow="true"
              
            >
            <el-form :inline="true" :model="searchForm">
              <el-form-item label="" class="formItem">
                <el-input
                  v-model="searchForm.user"
                  size="small"
                  placeholder="请输入项目名称"
                ></el-input>
              </el-form-item>
            </el-form>
            </SearchLeft>
          </template>
          <template slot="title">
            <div class="title">今日报警({{ 506 }})</div>
          </template>
          <el-table-column type="index" label="排序" width="50" align="center">
          </el-table-column>
          <el-table-column prop="date" label="项目名称" align="center"> </el-table-column>
          <el-table-column prop="date" label="总数（台）" align="center"> </el-table-column>
        </CustomTables>
        <div class="tableBox">
        
        <!--右边列表  -->
      <div class="form">
        <SearchHead
          @search="search"
          @reset="reset"
          :isExport="true"
          @tableExport="tableExport"
        >
          <el-form :inline="true" :model="searchForm">
            
            <el-form-item label="姓名">
              <el-input
                v-model="searchForm.user"
                size="small"
                placeholder="姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否过期">
              <el-select
                v-model="searchForm.region"
                size="small"
                placeholder="是否过期"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input
                v-model="searchForm.user"
                size="small"
                placeholder="联系方式"
              ></el-input>
            </el-form-item>
           
          </el-form>
        </SearchHead>
        <CustomTables
          :data="tableData"
          :headerCellStyle="headerCellStyle"
          @selectionChange="handleSelectionChange"
          height="55vh"
          :total="total"
          title="驾驶员信息"
        >
        
          <!-- <template slot="btns">
            <el-button type="success" size="small" @click="addFrom"
            icon="el-icon-plus"  class="btn">新建</el-button
            >
          </template> -->
        
          <el-table-column type="selection" align="center" width="55">
      </el-table-column>
          <el-table-column prop="date" label="司机照片"  align="center"> </el-table-column>
          <el-table-column prop="name" label="司机姓名"  align="center"> </el-table-column>
          <el-table-column prop="name" label="性别"  align="center"> </el-table-column>
          <el-table-column prop="name" label="联系方式"  align="center"> </el-table-column>
          <el-table-column prop="name" label="驾驶证颁发时间"  align="center"> </el-table-column>
          <el-table-column prop="name" label="驾驶证编号"  align="center"> </el-table-column>
          <el-table-column prop="name" label="驾驶证结束时间"  align="center"> </el-table-column>
          <el-table-column prop="name" label="是否过期"  align="center"> </el-table-column>
        </CustomTables>
      </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import CustomTables from "../../components/common/CustomTables";
  import SearchHead from "../../components/common/SearchHead";
  import SearchLeft from "../../components/common/SearchLeft.vue";
  import monitor from "../../api/modules/monitor";
  export default {
    components: { CustomTables, SearchHead,SearchLeft },
    props:['projId'],
    name: "monitorTowerDriver",
    data() {
      return {
        tableData: [
          
        ],
        headerCellStyle: {
          backgroundColor: "#f3f2f7",
          color: "#373628",
        },
        searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      
        options: [
      
      ],
        total:0
      };
    },
    mounted() {

      this.getProject()
    },
    methods: {
      getProject() {
      this.searchForm.projId = this.projId;
      monitor.getProjectDeviceCountTower(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
        
        // if( this.tableData.length>0){
        //   this.ProjectDeviceCount(this.tableData[0].projId);
        // }else{
        
        // }
       
      });
    },
      search() {
        console.log("searchForm", this.searchForm);
      },
      reset() {
        this.searchForm = {};
      },
      handleClick(tab, event) {
        console.log(tab, event);
      },
      tableExport(){},
      handleSelectionChange(){}
    },
  };
  </script>
  
  <style scoped lang="less">
  .content {
    display: flex;
    justify-content: space-between;
  }
  .tableBox {
    // margin-left: 10px;
    width: 67%;
  }
  .tableHear {
    width: 32%;
    // background:#e4ebf1
  }
  .title {
    margin-bottom: 10px;
  color: #000;
  }
  
  .formItem{
    margin-bottom: 0px;
  }
  </style>
  
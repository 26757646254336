import api from '../httpServer'
import config from '../config'
// import store from '@/store/index'

const organizationTree ={
    getOrganizationTree(data) {
        return api.get(`${config.headerUrl}organization/getOrganizationTree`,data)
    },
    //根据条件查询组织机构树-至公司
    getOrganizationCompanyTree(data) {
        return api.get(`${config.headerUrl}organization/getOrganizationCompanyTree`,data)
    },

    getOrganizationList(data) {
        return api.get(`${config.headerUrl}organization/getOrganizationList`,data)
    },
    getOrganizationCompanyList(data) {
        return api.get(`${config.headerUrl}organization/getOrganizationCompanyList`,data)
    },
}

export default organizationTree
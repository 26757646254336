<template>
    <div class="pageMain">
      <div class="flex" v-if="infoShow">
        <!-- 组织项目树 -->
  
        <OriginTree class="tree" :filterText="filterText" @checked="init">
          <template slot="search">
            <SearchLeft @search="search" @reset="reset"   
              info="项目信息"
              :iconShow="false">
              <el-form :inline="true" :model="searchForm">
                <el-form-item label="" class="formItem">
                  <el-input
                    v-model="filterText"
                    size="small"
                    placeholder="项目"
                  ></el-input>
                </el-form-item>
              </el-form>
            </SearchLeft>
          </template>
        </OriginTree>
  
        <!--右边列表  -->
        <div class="form">
          <SearchHead
            @search="search"
            @reset="reset"
            :isExport="true"
          >
            <el-form :inline="true" :model="searchForm">
               
              <el-form-item label="姓名">
                <el-input
                  v-model="searchForm.operName"
                  size="small"
                  placeholder="姓名"
                ></el-input>
              </el-form-item>
              
              <el-form-item label="联系电话	">
                <el-input
                  v-model="searchForm.operMobile"
                  size="small"
                  placeholder="联系电话	"
                ></el-input>
              </el-form-item>
           
            </el-form>
          </SearchHead>
          <CustomTables
            :data="tableData"
            :headerCellStyle="headerCellStyle"
            @selectionChange="handleSelectionChange"
            @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
         
            :total="total"
            title="塔机司机"
          >
            <!-- <template slot="btns">
              <el-button
                type="success"
                size="small"
                @click="addFrom"
                icon="el-icon-plus"
               
                class="btn"
                >抓拍</el-button
              >
            </template> -->
           
            <el-table-column type="selection" align="center" width="55">
            </el-table-column>
            <el-table-column prop="projName" label="机构名称" align="center">
            </el-table-column>
            <el-table-column prop="sjnum" label="司机人数" align="center">
            </el-table-column>
            <el-table-column prop="snum" label="上工人数" align="center">
            </el-table-column>
            <el-table-column prop="jnum" label="驾驶证总数" align="center">
            </el-table-column>
            <el-table-column prop="gnum" label="驾驶证过期数" align="center">
             
            </el-table-column>
            
            <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
                <span
                @click="detailItem(scope.row)"
                class="detail delete"
              >
              查看
            </span>
          
            </template>
          </el-table-column>
            
          </CustomTables>
        </div>
      </div>
      <!-- <monitorTowerDriver v-if="editShow" :projId="projId"></monitorTowerDriver> -->
    </div>
  </template>
  
  <script>
  import OriginTree from "../../../components/common/OriginTree.vue";
  import SearchHead from "../../../components/common/SearchHead";
  import CustomTables from "../../../components/common/CustomTables";
  import SearchLeft from "../../../components/common/SearchLeft.vue";
  import monitorTowerDriver from '../../../components/monitor/monitorTowerDriver.vue'
  import monitor from '../../../api/modules/monitor'

  export default {
    components: {
      OriginTree,
      SearchHead,
      CustomTables,
      SearchLeft,
      monitorTowerDriver
    },

    data() {
      return {
        editShow:false,
      infoShow:true,
      searchForm: {
          pageNum: 1,
          pageSize: 10,
        },
        tableData: [
      
        ],
        options: [
         
        ],
        headerCellStyle: {
          backgroundColor: "#f3f2f7",
          color: "#373628",
        },
        total: 0,
        show: true,
        filterText:'',
        projId:''
      };
    },
    mounted() {},
    methods: {
      addFrom() {},
      init(val) {
        this.searchForm.orgaId = val.orgaId;
        this.orgaId=val.orgaId;
        this.searchForm.currPage = 1;
        this.getList();
      },
      getList() {
        monitor.getPagestower(this.searchForm).then((res) => {
          this.total = res.data.total;
          this.tableData = res.data.list;
        });
     
      },
      search() {
        console.log("searchForm", this.searchForm);
        this.searchForm.pageNum = 1;
        this.searchForm.pageSize = 10;
        this.getList();
      },
      reset() {
        this.searchForm = {
          pageNum: 1,
          pageSize: 10,
          orgaId:this.orgaId
        };
        this.getList();
      },
      tableExport() {
        // 导出
      },
      handleSelectionChange(val) {
        console.log(val);
      },
      handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
      detailItem(item){
        console.log('item',item);
        
          //详情
        //   this.editShow = true
        // this.infoShow = false
        // this.projId=item.projId
        this.$router.push({
       path: `/view/monitor/towerOperatorDel`,
       query: {
        item: item.orgaId,
      
    }
     });
      },
      
    },
  };
  </script>
  
  <style scoped lang="less">
.flex {
  display: flex;
  height: 100%;
  
  // justify-content: space-between;
}
.tree {
  width: 20%;
  height: 96%;
  
}
  .form {
    width: 78%;
    margin-left: 2%;
  }
  .formItem {
    margin-bottom: 0px;
  }
  .btn {
    background-color: #008f4d;
    // color: #008f4d;
  }
  
  .detail{
      color: #008f4d;
  }
  </style>
  
<template>
  <div class="tree">
    <slot name="search"></slot>
    <!-- <el-input
  placeholder="输入关键字进行过滤"
  v-model="filterText">
</el-input> -->
    <el-tree
      class="filter-tree"
      :data="data"
      :props="defaultProps"
      accordion
      lazy
      :current-node-key="currentNodekey"
      :default-expanded-keys="[currentNodekey]"
      :highlight-current="true"
      node-key="orgaId"
      @node-click="handleNodeClick"
      :filter-node-method="filterNode"
      :load="loadNode"
      ref="tree"
    >
    <template #default="{ data, node }">
        <el-row type="flex" justify-content="space-between" align="middle">
          <el-col :span="20">
            <span class="el-icon-location-outline icons">
              {{ data.orgaName }}
            </span>
          </el-col>
        </el-row>
      </template>
    </el-tree>
  </div>
</template>

<script>
import SearchLeft from "../../components/common/SearchLeft";
import organizationTree from "../../api/modules/organizationTree";
export default {
  components: {
    SearchLeft,
  },
  name: "OriginTree",
  props: ["filterText"],
  data() {
    return {
      // filterText:'',
      searchForm: {},
      defaultExpandedKeys: [],
      currentNodekey: "",
      data: [],
      defaultProps: {
        children: "children",
        label: "orgaName",
        value: "id", // 节点标识
        //  isLeaf: 'isLeaf',
      },
      selectedNodeId: null,
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
      // this.loadNode();
    },
  },
  mounted() {
    
    this.loadNode({ level: 0, data: { orgaId: 0 } }, (children) => {
      if (children.length > 0) {
        this.defaultExpandedKeys = [children[0].orgaId];
       
        
        this.currentNodekey = children[0].orgaId;
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(this.currentNodekey);
        });
        this.$emit("checked", children[0]);
       
      }
    });
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.orgaName.indexOf(value) !== -1;
    },
    loadNode(node, resolve) {
      // node.level = node.data.orgaLevel
    
      let params = {
        orgaId: node.data.orgaId,
        orgaLevel: node.level || 0,
      };
      organizationTree
        .getOrganizationList(params)
        .then((res) => {
          if (res.data.length > 0) {
           
            
            return resolve(res.data);
          } else {
            return resolve([]);
          }
        })
        .catch((error) => {
          console.error("Error loading node data:", error);
          return resolve([]);
        });
    },
    handleNodeClick(val) {
      this.currentNodekey = val.orgaId;
      // 更新默认展开的节点
      // this.currentNodekey = "";
      this.$refs.tree.setCurrentKey(this.currentNodekey);
      this.$emit("checked", val);
    },
  },
};
</script>

<style lang="less" scoped>
.tree {
  padding: 20px;
  border: 1px solid #efefef;
  // height: calc(100% - 40px);
  // height: 100%;
  overflow: auto;
  border-radius: 2%;
}
/deep/.el-tree-node:focus > .el-tree-node__content {
  color: #3f9f5f; //节点的字体颜色
  background-color: #f5f7fa;
}
/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #f5f7fa;
  color: #3f9f5f; //节点的字体颜色
}
.icons {
  font-size: 14px;
}
</style>
